@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #fffbeb;
  --foreground: #171717;
}
/* removed dark mode support */
/* @media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
} */

/* body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
} */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@font-face {
  font-family: 'Comfortaa';
  src: ('/fonts/Comfortaa-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: ('/fonts/Comfortaa-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: ('/fonts/Comfortaa-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: ('/fonts/Comfortaa-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: ('/fonts/Comfortaa-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

/* Update the global font-family */
body {
  color: var(--foreground);
  background: var(--background);
  font-family: 'Comfortaa', sans-serif;
}